import React from 'react';

import CookieConsentBanner from '../../CookieConsentBanner';
import ContactUs from './ContactUs';
import Hero from './Hero';
import PaimaEngineProContent from './PaimaEngineProContent';

const PaimaEngineProfessionalLayout = () => {
  return (
    <div className="relative">
      <CookieConsentBanner />
      <Hero />
      <PaimaEngineProContent />
      <ContactUs />
    </div>
  );
};

export default PaimaEngineProfessionalLayout;
