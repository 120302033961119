import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Layout from '../components/v2/Layout';
import PaimaEngineProfessionalLayout from '../components/v2/paima-engine-professional/PaimaEngineProfessionalLayout';

const PaimaEngineProfessional = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Paima Engine Professional')}>
      <PaimaEngineProfessionalLayout />
    </Layout>
  );
};

export default PaimaEngineProfessional;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
