import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

const ContactUs = () => {
  return (
    <section className="bg-engine-green">
      <div className="container-slim relative z-30 pt-40 md:pt-[10vw] pb-[15vw] md:pb-[10vw] xl:pt-[8vw] xl:pb-[6vw]">
        <h3 className="text-32 md:text-56 font-bold text-white text-center font-heading z-30 relative">
          <Trans>Contact us to get started</Trans>
        </h3>
        <h4 className="text-18 md:text-24 text-white text-center font-heading mt-8 z-30 relative">
          <Trans>enter the jungle!</Trans>
        </h4>
        <div className="flex items-center justify-center mt-24 md:mt-40">
          <div className="bg-white/20 rounded-[40px] inline-block mx-auto py-8 md:py-16 px-24">
            <a
              href="mailto:pro@paimastudios.com"
              className="font-bold text-white text-center text-18 md:text-24 hover:underline z-40 relative"
            >
              pro@paimastudios.com
            </a>
          </div>
        </div>
        <img
          src="/images/paima-pro-contact-us-character-1.png"
          className="absolute top-0 xl:top-[2vw] right-0 hidden md:block max-w-[250px] lg:max-w-[350px] -z-1"
          alt=""
        />
      </div>
      <div className="relative">
        <div className="container-slim relative">
          <img
            src="/images/paima-pro-contact-us-character-2.png"
            className="absolute bottom-[50px] xl:bottom-[3vw] left-0 hidden md:block max-w-[250px] lg:max-w-[300px] -z-1"
            alt=""
          />
        </div>
      </div>
      <div className="relative h-[100px] md:h-[200px] xl:h-[400px] oval-clip" />
    </section>
  );
};

export default ContactUs;
