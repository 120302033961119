import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

const partners = [
  {
    name: 'Microsoft',
    logo: 'logo-microsoft.png'
  },
  {
    name: 'Algorand',
    logo: 'logo-algorand.png'
  },
  {
    name: 'Astar',
    logo: 'logo-astar.png'
  },
  {
    name: 'Milkomeda',
    logo: 'logo-milkomeda.png'
  }
];

const professionals = [
  {
    name: 'MLabs',
    logo: 'logo-mlabs-gray.png'
  },
  {
    name: 'Pacific Meta',
    logo: 'logo-pacific-meta-gray.png'
  },
  {
    name: 'Vacuumlabs',
    logo: 'logo-vacuumlabs-gray.png'
  }
];

const Hero = () => {
  return (
    <section className="bg-engine-green text-white pt-32 md:pt-48 lg:pt-80 relative overflow-hidden">
      <img
        src="/images/paima-engine-hero-chip.webp"
        alt=""
        className="absolute lg:top-0 md:bottom-[20vw] lg:bottom-[6vw] -right-[20vw] lg:right-0 2xl:right-[calc((100vw-1114px)/2-13vw)] xl:max-w-[728px] xl:max-h-[728px] sm:max-w-[200px] sm:max-h-[200px] md:max-w-[384px] md:max-h-[384px] z-0 hidden md:block"
      />
      <div className="container-slim z-10 relative">
        <div className="md:max-w-[640px]">
          <h1 className="text-40 md:text-64 font-bold font-heading text-center md:text-left">
            <Trans
              i18nKey="Become a Paima Engine Professional."
              components={[<span className="block" />, <span className="sm:text-48 md:text-72 lg:text-96" />]}
            />
          </h1>
          <p className="text-18 text-engine-gray mt-16 text-center md:text-left">
            <Trans>A trusted B2B partner in the Paima Ecosystem vetted by the Paima Studios team</Trans>
          </p>
        </div>
        <div className="sm:py-24 sm:px-16 rounded-[32px] mt-24 mb-24 md:mt-72 max-w-[280px] md:max-w-[440px] mx-auto pb-[5vw] sm:pb-0">
          <ul className="mt-16 md:mt-24 gap-24 grid grid-cols-2">
            {partners.map((partner, index) => (
              <li key={index}>
                <img
                  src={`/images/${partner.logo}`}
                  alt={`Logo for ${partner.name}`}
                  className="mx-auto w-full max-w-[120px] max-h-[27px] md:max-w-[180px] md:max-h-[40px]"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="jungle-oval-clip bg-engine-black pt-[10vw] sm:-mt-[5vw]">
        <div className="py-24 px-16 rounded-[32px] sm:max-w-[600px] sm:mx-auto pt-32 pb-32 sm:pt-80 sm:pb-40">
          <p className="text-white text-16 font-bold text-center mb-24">
            <Trans>Join a community of existing professionals</Trans>
          </p>
          <ul className="mt-16 md:mt-24 gap-32 grid grid-cols-1 sm:grid-cols-3">
            {professionals.map((partner, index) => (
              <li key={index}>
                <img
                  src={`/images/${partner.logo}`}
                  alt={`Logo for ${partner.name}`}
                  className="mx-auto w-full max-w-[120px] max-h-[27px] md:max-w-[180px] md:max-h-[40px]"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Hero;
