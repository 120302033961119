import { Trans,useTranslation } from 'gatsby-plugin-react-i18next';
import React,{ ReactElement } from 'react';

import SectionTitle from './SectionTitle';

const whyPaimaPoints = [
  <Trans>Leverage existing environments (ex: Unity) for web3</Trans>,
  <Trans>Connect in-app data directly with user-held NFTs</Trans>,
  <Trans>Connect to multiple chains with a single application</Trans>,
  <Trans>Connect app-specific NFTs to other existing dApps</Trans>,
  <Trans>Upgrade apps with minimal or no smart contract change</Trans>,
  <Trans>Leverage L2 scalability for low transaction fees</Trans>,
  <Trans>Onboard users without bridge or common web3 risks</Trans>,
  <Trans>Composes with new tech like k cryptography and Al</Trans>
];

const becomeAProPoints = [
  <Trans>Educational material and online courses about the Paima Engine</Trans>,
  <Trans>Direct support from the Paima Studios team</Trans>,
  <Trans>Increased visibility by being listed on the Paima website</Trans>,
  <Trans>Lead generation support from Paima Studios</Trans>
];

const TimelineItem = ({ title }: { title: string | ReactElement }) => {
  return (
    <div className="bg-engine-green flex items-center justify-center text-center font-bold text-18 px-16 py-16 rounded-[12px] text-white relative z-20">
      <p>{title}</p>
    </div>
  );
};

const PaimaEngineProContent = () => {
  const { t } = useTranslation();

  return (
    <section className="pt-32 md:pt-48 lg:pt-80 pb-32 md:pb-48 lg:pb-80 relative overflow-hidden">
      <div className="container-slim z-10 relative">
        <div>
          <SectionTitle title={t('Why Paima')} />
          <ul className="md:columns-2 list-disc mt-24 md:mt-40 pl-24">
            {whyPaimaPoints.map((point, index) => (
              <li key={index} className="text-16 md:text-18">
                {point}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-40 md:mt-64 lg:mt-80">
          <SectionTitle title={t('Become a pro')} />
          <div className="mt-32 md:mt-40">
            <div className="flex flex-col-reverse md:items-center md:flex-row gap-16 md:gap-32 lg:gap-48">
              <div>
                <h3 className="relative text-24 md:text-32 font-bold font-heading">
                  <Trans>Why?</Trans>
                </h3>
                <ul className="list-disc mt-24 pl-24">
                  {becomeAProPoints.map((point, index) => (
                    <li key={index} className="text-16 md:text-18">
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="md:flex-1 md:shrink-0 md:basis-[300px]">
                <img src={`/images/paima-engine-overview-1.webp`} alt="" className="rounded-[24px] md:rounded-[40px]" />
              </div>
            </div>
          </div>
          <div className="mt-32 md:mt-40">
            <h3 className="relative text-24 md:text-32 font-bold font-heading">
              <Trans>How?</Trans>
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-24 md:gap-40 relative mt-24 md:mt-40">
              <div className="absolute h-full w-[4px] md:h-[4px] md:w-full bg-engine-green left-0 right-0 md:top-0 md:bottom-0 m-auto z-0" />
              <TimelineItem title={t("Contact our team")} />
              <TimelineItem title={t("Pass online tutorial")} />
              <TimelineItem title={t("Complete registration")} />
              <TimelineItem title={t("Joint announcement + website listing")} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaimaEngineProContent;
